import {
  type Dispatch,
  type JSXElementConstructor,
  type ReactElement,
  type SetStateAction,
  useState,
} from 'react';
import { type Selection } from 'react-aria-components';
import { useFieldArray, useForm } from 'react-hook-form';
import { useListData } from 'react-stately';

import { type InterestsData } from '@module/mdrt-org/account-settings/components';
import styles from '@module/mdrt-org/account-settings/components/site-preferences-form/interests-section/interests-section.module.scss';
import { Dialog, useUserContext } from '@module/mdrt-org/shared/components';
import { TagsList } from '@module/mdrt-org/shared/components/tags-list/tags-list';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data';
import {
  AccountSettingsFormStep,
  AccountSettingsMutationKey,
} from '@module/mdrt-org/shared/utils/enums';
import {
  useAddDefaultErrorToast,
  useMutationHandler,
} from '../../../../../../mdrt-org/shared/src/lib/utils/hooks';
import { Button, ButtonVariant, FormWrapper, ModalTrigger } from '@shared/ui-components';
import { ButtonType, formBaseConfig, type ServerActionResponse } from '@shared/utils';

import { useAccountSettingsPageContext } from '../../providers/account-settings-page-context';
import {
  type UserInterestsSettingsPreferenceData,
  type UserInterestsSettingsPreferenceFormData,
} from '../../utils/types/form-data';

export type InterestsFormModalCmsContent = {
  cancelButtonLabel: string;
  interestModalSubTitle: string;
  interestModalTitle: string;
  interestsErrorMessage: string;
  saveButtonLabel: string;
};

type InterestsSectionProps = {
  allTopics: InterestsData;
  cmsContent: InterestsFormModalCmsContent;
  customerInterests: number[];
  isOpen: boolean;
  modalTrigger: ReactElement<any, string | JSXElementConstructor<any>>;
  onSuccessfulSubmit: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onDismiss: () => void;
};

const INTERESTS_FIELD = 'interests';

export const InterestsFormModal = ({
  allTopics,
  cmsContent,
  customerInterests,
  isOpen = false,
  modalTrigger,
  onDismiss,
  onSuccessfulSubmit,
  setIsOpen,
}: InterestsSectionProps) => {
  const {
    cancelButtonLabel,
    interestModalSubTitle,
    interestModalTitle,
    interestsErrorMessage,
    saveButtonLabel,
  } = cmsContent;

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const formMethods = useForm<UserInterestsSettingsPreferenceFormData>({
    ...formBaseConfig,
    defaultValues: {
      interests: customerInterests
        ? allTopics.filter((topic) => customerInterests.includes(topic.topicId))
        : [],
    },
  });

  const { append, fields, remove } = useFieldArray<UserInterestsSettingsPreferenceFormData>({
    control: formMethods.control,
    name: INTERESTS_FIELD,
  });

  const fieldTopicIds = fields.map(({ topicId }) => topicId);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(fieldTopicIds));

  const selectedInterests = useListData({
    initialItems: fields.map(({ name, topicId }) => ({
      id: topicId,
      name,
    })),
  });

  const allInterests = useListData({
    initialItems: allTopics.map(({ name, topicId }) => ({
      id: topicId,
      name,
    })),
  });

  const saveInterests = (newKeys: Selection) => {
    const selectedTopics = Array.from(newKeys);

    const newTopics = selectedTopics
      .filter((key) => !fields.some(({ topicId }) => topicId === key))
      .map((key) => allInterests.getItem(key));

    const removedFieldIndexes = fields
      .map(({ topicId }, index) => (selectedTopics.includes(topicId) ? null : index))
      .filter((index): index is number => index !== null);

    if (removedFieldIndexes) {
      remove(removedFieldIndexes);

      for (const { id } of selectedInterests.items.filter(
        (item) => !selectedTopics.includes(item.id)
      )) {
        selectedInterests.remove(id);
      }
    }

    append(newTopics.map(({ id, name }) => ({ name, topicId: id })));
    selectedInterests.append(...newTopics);
  };

  const handleSelectionChange = (newKeys: Selection) => {
    const selectedSize = newKeys instanceof Set ? newKeys.size : 0;

    setSelectedKeys(newKeys);
    saveInterests(newKeys);

    if (selectedSize >= 3 && errorMessage) {
      setErrorMessage(undefined);
    }
  };

  const cancelSelection = () => {
    if (errorMessage) setErrorMessage(undefined);

    setSelectedKeys(new Set(fieldTopicIds));
    setIsOpen(false);
    onDismiss();
  };

  const { resetCommunicationPreferencesContextData, resetCustomerProfileContextData } =
    useAccountSettingsPageContext();

  const { personId } = useUserContext();
  const addErrorToast = useAddDefaultErrorToast();

  const { reset } = formMethods;

  const onFailure = (result: ServerActionResponse) => {
    if (result.status !== 200 && 'message' in result) {
      resetCustomerProfileContextData();
      resetCommunicationPreferencesContextData();
      addErrorToast();
    }
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<UserInterestsSettingsPreferenceData>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => {
      reset({ ...formMethods.getValues() });
      setIsOpen(false);
      onSuccessfulSubmit();
    },
  });

  const handleSubmit = async (data: UserInterestsSettingsPreferenceFormData) => {
    if (data.interests.length < 3) {
      setErrorMessage(interestsErrorMessage);
      return;
    }

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.Interests,
        sitePreferenceData: {
          interests: data.interests && data.interests.map((interest) => interest.topicId),
        },
      },
      personId,
    });
  };

  return (
    <ModalTrigger isOpen={isOpen} renderTrigger={() => modalTrigger}>
      {() => (
        <FormWrapper
          className={styles['site-preferences-form']}
          dataTestId="interests-preferences-form"
          formMethods={formMethods}
          onSubmit={handleSubmit}
        >
          <Dialog
            footer={
              <div className={styles['interests-modal-controls']}>
                <Button onClick={cancelSelection} variant={ButtonVariant.Secondary}>
                  {cancelButtonLabel}
                </Button>
                <Button
                  disabled={formMethods.getValues(INTERESTS_FIELD).length < 3}
                  type={ButtonType.SUBMIT}
                >
                  {saveButtonLabel}
                </Button>
              </div>
            }
            footerClassName={styles['interests-modal-footer']}
            hasCloseButton={false}
            headerClassName={styles['interests-modal-header']}
            isLoading={isMutationPending}
            subtitle={interestModalSubTitle}
            title={interestModalTitle}
            titleId="interest-modal-title"
          >
            <div className={styles['interests-modal-content']}>
              <TagsList
                aria-labelledby="interest-modal-title"
                centerContent
                errorMessage={errorMessage}
                items={allInterests.items}
                onSelectionChange={handleSelectionChange}
                selectedKeys={selectedKeys}
                selectionMode="multiple"
              />
            </div>
          </Dialog>
        </FormWrapper>
      )}
    </ModalTrigger>
  );
};
