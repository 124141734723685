'use client';

import { useEffect, useState } from 'react';

import { type DevelopedFeatures } from './constants';

export const useFeatureFlags = () => {
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);

  useEffect(() => {
    const fetchValue = async () => {
      const response = await fetch('/api/feature-flags/enabled');
      if (response.ok) {
        const responseJson = await response.json();
        setEnabledFeatures(responseJson.value);
      }
    };

    fetchValue();
  }, []);

  const getIsFeatureEnabled = (featureKey: DevelopedFeatures) => {
    return enabledFeatures?.includes(featureKey);
  };

  return { enabledFeatures, getIsFeatureEnabled };
};
